import React, { useState, useEffect } from "react";
import { QuizCategory, QuizCategorySlug } from "../../../Api/Api";
import SkeletonCard from "./SkeletonCard";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import SEO from "../../../SEO";
var DefaultImg = "/images/kavishalaImages.png";
const MainCategory = () => {
  const [allCategory, setAllCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const paths = window.location.pathname;
  const path = window.location.pathname.split("/");
  console.log("cvbnnb", path);
  useEffect(() => {
    const loadData = () => {
      QuizCategorySlug(path[2], path[3]).then((res) => {
        console.log("redefgbsv", res);
        if (res?.status === 200) {
          setLoading(true);
          setAllCategory(res?.data);
        }
      });
    };
    loadData();
  }, []);

  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  return (
    <>
      <SEO
        title={`Kavishala Academy | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}`}
        og_type="article"
        og_url={paths}
        og_title={`Kavishala Academy | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}`}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <div style={{ paddingTop: "80px" }}>
        <h2 className="message">Choose Quiz Category</h2>
      </div>
      {!loading ? (
        <div className="loaders">
          <Loader />
        </div>
      ) : (
        <div className="exam-chapter-main">
          {allCategory?.map((value) => {
            console.log("kjhgbhnj", value);
            return (
              <div
                className="exam-chapter"
                onClick={() =>
                  navigate(`/quiz/${path[2]}/${path[3]}/${value?.slug}`)
                }
              >
                <Card className="Quiz-card">
                  <CardMedia
                    sx={{ height: 180 }}
                    image={value?.image}
                    title={value?.quiz_name}
                  />
                </Card>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="cardContentName"
                >
                  {value?.quiz_name}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MainCategory;
