import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
const Syllabus = ({ data }) => {
  console.log("kjbv jhghj", data);

  return (
    <>
      <div className="exam-chapter-main-container">
        <center>
          <h2>{data?.title}</h2>
        </center>

        <center>
          <img src={data?.img} style={{ maxWidth: "40%" }} />
        </center>
        <p
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        />
        {data?.pdf === null ? (
          ""
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "5% auto",
            }}
          >
            <Button
              variant="contained"
              onClick={() => window.open(data?.pdf)}
              style={{
                borderRadius: 5,
                backgroundColor: "#00102c",
                fontSize: "14px",
                margin: "0 5%",
              }}
            >
              Read / Download
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Syllabus;
