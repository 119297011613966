import React from "react";
import QuizHome from "../../Component/Exam/Quiz/QuizHome";
const QuizPage = () => {
  return (
    <div>
      <QuizHome />
    </div>
  );
};

export default QuizPage;
