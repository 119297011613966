import React from "react";
import Exams from "../../images/Exams.jpeg";
import ExamBooks from "../../images/ExamBooks.jpeg";
import ExamsQuizs from "../../images/Quiz.jpeg";
import { useNavigate } from "react-router-dom";
import "./Exam.scss";
const Home_Category = () => {
  const navigate = useNavigate();
  const pages = [
    {
      image: Exams,
      link: "/exams",
    },
    {
      image: ExamsQuizs,
      link: "/quiz",
    },
    {
      image: ExamBooks,
      link: "/books",
    },
  ];
  return (
    <div className="category_home_conatiner">
      <center>
        <h2 className="heading"> Academy - Competitive Exams</h2>
      </center>
      <div className="home_container_row">
        {pages.map((page) => {
          return (
            <div className="category_image_container">
              <img
                src={page?.image}
                className="category_image_container_image"
                onClick={() => navigate(`${page?.link}`)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Home_Category;
