import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Quizs, QuizsPost } from "../../../Api/Api";
import ScoreTabs from "./ScoreTabs";
const QuestionsPage = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  console.log("fghgfg", slug);
  const [show, setShow] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState(1);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [slectetValue, setSlectetValue] = useState();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [time, setTime] = useState();
  const [visible, setVisible] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [coins, setCoins] = useState([]);

  const [result, setResult, correctAnswer] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
  });

  useEffect(() => {
    const loadData = () => {
      Quizs(slug)
        .then((res) => {
          console.log("res", res);
          setData(res?.data);
          setTime(res?.data?.expiration_time);
        })
        .catch((e) => {
          setVisible(true);
          setData(e.response.data);
        });
    };
    loadData();
  }, [slug]);
  var question;
  var choices = [];

  const { quiz } = data;
  if (quiz !== undefined) {
    question = quiz[activeQuestion - 1]?.question;
    choices = quiz[activeQuestion - 1]?.questions;
  }
  const setTimer = () => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          setSlectetValue(true);
          setSelectedAnswer(2);
          clearInterval(timer);
          return time;
        } else {
          return time - 1;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    setTimer();
  }, []);

  const handleNext = () => {
    if (!time) setTimer();
    if (activeQuestion > quiz?.length - 1) {
    } else if (selectedAnswer) {
      setActiveQuestion(activeQuestion + 1);
      setSelectedAnswer(null);
      setTime(data?.expiration_time);
    } else setError("Please select an option first");
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleCheck = (text, key) => {
    setSelectedAnswer(key);
    setSlectetValue(text);
    if (text === true) {
      setScore(score + 1);
    }
  };

  const onAnswerSelected = (text, key) => {
    if (
      selectedAnswer === key &&
      slectetValue === text &&
      slectetValue === true
    )
      return "selectd";
    else if (
      selectedAnswer === key &&
      slectetValue === text &&
      slectetValue === false
    )
      return "wrong";
    else if (text === true) return "selectd";
  };
  const sendData = (quiz_id) => {};
  const handleSubmit = () => {
    setShow(true);
  };

  const handleIsShow = () => setIsShow(true);
  const handleClose = () => setIsShow(false);

  return (
    <>
      {visible === false ? (
        <div>
          {!show && (
            <div className="mainContainer">
              <div className="QuestionContainer">
                <div className="questionTopContainer">
                  <div>
                    <h6 className="QuizHeading2"> {data?.quiz_name}</h6>
                  </div>
                  <div className="nextConatains">
                    {time === 0 ? (
                      <div className="timesUp">Time's up !</div>
                    ) : (
                      ""
                    )}
                    <div className="timeCount">
                      <h6 className="timeCountnum">{time}</h6>
                    </div>
                    <div>
                      <span style={{ fontWeight: "600" }}>
                        {activeQuestion}
                      </span>{" "}
                      of{" "}
                      <span style={{ fontWeight: "600" }}>{quiz?.length}</span>
                    </div>
                    <div className="score">
                      Score: <span style={{ fontWeight: "600" }}>{score}</span>
                    </div>
                  </div>
                </div>
                <div className="questionse_condContainer">
                  <h1 className="Question">{question}</h1>
                  <div className="options">
                    {choices &&
                      choices.map((answer, key) => {
                        return (
                          <button
                            key={key}
                            className={`inputItems ${
                              selectedAnswer &&
                              onAnswerSelected(answer.is_correct, key + 1)
                            }`}
                            onClick={() =>
                              handleCheck(answer.is_correct, key + 1)
                            }
                            disabled={selectedAnswer}
                          >
                            {answer?.option_text}
                            <CancelIcon className="quizPop_cancelIcons" />
                            <CheckCircleIcon className="quizPop_rightIcons" />
                          </button>
                        );
                      })}
                  </div>
                </div>
                <div className="nextBtnContainer">
                  {activeQuestion > quiz?.length - 1 ? (
                    selectedAnswer === null ? (
                      ""
                    ) : (
                      <button onClick={handleSubmit} className="nextBtn">
                        Submit
                      </button>
                    )
                  ) : selectedAnswer === null ? (
                    ""
                  ) : (
                    <button className="nextBtn" onClick={handleNext}>
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          <div>
            {show && (
              <ScoreTabs score={(score * 100) / quiz?.length} coins={coins} />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default QuestionsPage;
