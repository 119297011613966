import React, { useState, useEffect } from "react";
import ScoreDashBoard from "./ScoreDashBoard";
import QuestionBtn from "./QuestionBtn";

const kavishalaImg = "/images/kavishalaImages.png";
const ScoreTabs = ({ score, coins }) => {
  const [selected, setSelected] = useState("");
  const [content, setContent] = useState([]);
  const handleChange = (key) => {
    setSelected(key);
    switch (key) {
      case "Score":
        setContent(content);
        break;
      case "Answers":
        setContent(content);
        break;
      case "LeaderBoads":
        setContent(content);
        break;
      default:
        break;
    }
  };

  const getComponent = () => {
    switch (selected) {
      case "Score":
        return <ScoreDashBoard score={score} coins={coins} />;
      case "Answers":
        return content.map((data, key) => <ScoreDashBoard />);
      case "LeaderBoads":
        return content.map((data, key) => <ScoreDashBoard />);
      default:
        return <h1 style={{ margin: "0" }}>No records found</h1>;
    }
  };

  useEffect(() => {
    setSelected("Score");
    setContent(content);
  }, []);
  return (
    <div className="Questionsbtn-container">
      <div className="Questionsbtn">
        <QuestionBtn selected={selected} setSelected={handleChange} />
      </div>
      <div
        style={{
          backgroundImage: `url(${kavishalaImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          maxWidth: "100%",
          height: "1000px",
          maxHeight: "568px",
        }}
      >
        <div style={{ paddingTop: "10%" }}>{getComponent()}</div>
      </div>
    </div>
  );
};
export default ScoreTabs;
