import React from "react";
import Home from "../../Component/AcademyHome/Home";
import SEO from "../../SEO";
const HomePage = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Academy | All Words`}
        og_url={"/words"}
        og_type="article"
        og_title={`Kavishala Academy | All Words`}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <Home />
    </div>
  );
};

export default HomePage;
