"use client";
import React from "react";
import QuestionsPage from "./QuestionsPage";

const Category = () => {
  return (
    <>
      <QuestionsPage />
    </>
  );
};

export default Category;
