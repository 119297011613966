import React, { useState, useEffect } from "react";
import { getExamSlug } from "../../../Api/Api";
import "../Exams/exam.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SkeletonCard from "../../Skeleton/SkeletonCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const Category = () => {
  const { slug } = useParams();
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = () => {
      getExamSlug(slug)
        .then((res) => {
          if (res.status === 200) {
            setLoading(true);
            setData(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
  }, [slug]);
  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };

  return (
    <div className="exam-chapter-main-container">
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <>
          <center>
            <h1>{path[1] === "quiz" ? "Quiz" : "Study Material Categories"}</h1>
          </center>

          <div className="exam-chapter-main">
            {data?.map((value) => {
              return (
                <div
                  className="exam-chapter"
                  onClick={() =>
                    navigate(
                      path[1] === "quiz"
                        ? `/quiz/${path[2]}/${value?.slug}`
                        : `/${path[2]}/study-materiel/${value?.slug}`
                    )
                  }
                >
                  <Card>
                    <CardMedia
                      sx={{ height: 150 }}
                      image={value?.image}
                      title={value?.subject_name}
                    />
                  </Card>
                  {/* <CardContent className="cardContent"> */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardContentName"
                  >
                    {value?.subject_name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="cardContentDesp"
                  >
                    {value?.discription}
                  </Typography>
                  {/* </CardContent> */}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Category;
