import React from "react";
import Category from "../../Component/Exam/Quiz/Category";
const QuizsCategories = () => {
  return (
    <div>
      <Category />
    </div>
  );
};

export default QuizsCategories;
