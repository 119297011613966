import React, { useState, useEffect } from "react";
import { getExam } from "../../../Api/Api";
import "../Exams/exam.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SkeletonCard from "../../Skeleton/SkeletonCard";
import SEO from "../../../SEO";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 4,
  opacity: "unset",
  transition: "unset",
};
const Exam = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [red_url, setRed_url] = useState("");
  const path = window.location.pathname;
  useEffect(() => {
    const loadData = () => {
      getExam().then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(true);
        }
      });

      console.log("path", path);
    };
    loadData();
  }, []);
  const handleOpen = (name) => {
    setRed_url(name);
    setOpenModal(true);
  };
  const redirectSyllabus = () => {
    navigate(`${red_url}/syllabus`);
  };
  const redirectContent = () => {
    navigate(`${red_url}/study-materiel`);
  };
  const handleModalClose = () => setOpenModal(false);
  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  console.log("lkjnbn", data);
  return (
    <div className="exam-chapter-main-container">
      <SEO
        title={`Kavishala Academy | ${
          path === "/exams" ? "Competitive Exams | Exams" : "Quiz"
        } `}
        og_url={path === "/exams" ? "/exams" : "/quiz"}
        og_type="article"
        og_title={`Kavishala Academy | ${
          path === "/exams" ? "Competitive Exams | Exams" : "Quiz"
        } `}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <>
          <center>
            <h1> {path === "/exams" ? "Exam Categories" : "Quiz For Exam"}</h1>
          </center>

          <div className="exam-chapter-main">
            {data?.map((value) => {
              return (
                <div
                  className="exam-chapter"
                  onClick={
                    path === "/exams"
                      ? () => handleOpen(value?.slug)
                      : () => navigate(`${value?.slug}`)
                  }
                >
                  <Card>
                    <CardMedia
                      sx={{ height: 150 }}
                      image={value?.image}
                      title={value?.name}
                    />
                  </Card>
                  {/* <CardContent className="cardContent"> */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardContentName"
                  >
                    {value?.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="cardContentDesp"
                  >
                    {value?.discription}
                  </Typography>
                  {/* </CardContent> */}
                </div>
              );
            })}
          </div>
        </>
      )}

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            component="h2"
            style={{ fontSize: "24px" }}
          >
            Choose anyone of them !
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, fontSize: 18 }}
          ></Typography>
          <div
            className="btn_modal_container"
            style={{
              boxSizing: "unset",
            }}
          >
            <Button
              variant="contained"
              onClick={redirectSyllabus}
              style={{
                borderRadius: 5,
                backgroundColor: "#F89920",
                fontSize: "14px",
                margin: "0 5%",
                boxSizing: "unset",
              }}
            >
              Syllabus
            </Button>
            <Button
              variant="contained"
              onClick={redirectContent}
              style={{
                borderRadius: 5,
                backgroundColor: "#F89920",
                fontSize: "14px",
              }}
            >
              Study Material
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Exam;
