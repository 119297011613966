// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category_home_conatiner {
  min-height: 500px;
  padding-top: 100px;
  margin: auto; }
  .category_home_conatiner .heading {
    font-size: 36px; }
  .category_home_conatiner .home_container_row {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .category_home_conatiner .home_container_row .category_image_container {
      width: 25%;
      margin: 2.5%;
      cursor: pointer; }
      .category_home_conatiner .home_container_row .category_image_container .category_image_container_image {
        width: 100%; }

@media (max-width: 480px) {
  .category_home_conatiner .heading {
    font-size: 24px; }
  .category_home_conatiner .home_container_row {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .category_home_conatiner .home_container_row .category_image_container {
      width: 90%;
      margin: 2.5% auto; }
      .category_home_conatiner .home_container_row .category_image_container .category_image_container_image {
        width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/Component/Exam/Exam.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,kBAAkB;EAClB,YAAY,EAAA;EAHhB;IAKQ,eAAe,EAAA;EALvB;IAQI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,6BAA6B,EAAA;IAXjC;MAaQ,UAAU;MACV,YAAY;MACZ,eAAe,EAAA;MAfvB;QAiBY,WAAW,EAAA;;AAKvB;EAEI;IAEQ,eAAe,EAAA;EAFvB;IAKI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,6BAA6B,EAAA;IARjC;MAUQ,UAAU;MACV,iBAAiB,EAAA;MAXzB;QAaY,WAAW,EAAA,EACd","sourcesContent":[".category_home_conatiner{\n    min-height: 500px;\n    padding-top: 100px;\n    margin: auto;\n    .heading{\n        font-size: 36px;\n    }\n.home_container_row{\n    margin: auto;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n    .category_image_container{\n        width: 25%;\n        margin: 2.5%;\n        cursor: pointer;\n        .category_image_container_image{\n            width: 100%;\n        }\n    }\n}\n}\n@media(max-width:\"480px\")\n{\n    .category_home_conatiner{\n        .heading{\n            font-size: 24px;\n        }\n    .home_container_row{\n        margin: auto;\n        display: flex;\n        flex-wrap: wrap;\n        justify-content: space-evenly;\n        .category_image_container{\n            width: 90%;\n            margin: 2.5% auto;\n            .category_image_container_image{\n                width: 100%;\n            }\n        }\n    }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
