import React from "react";
import HomePage from "./Pages/AcademyHome/HomePage";
import AllData from "./Pages/AllPage/AllData";
import Footer from "./Component/Footer/Footer";
import Search from "./Component/Search/Search";
import Home from "./Pages/CompetitiveExams/Home";
import Exam from "./Component/Exam/Exams/Exam";
import QuizPage from "./Pages/Quizs/QuizPage";
import Books from "./Component/Exam/Others/Books";
import AllSyllbus from "./Pages/Syllabus/AllSyllbus";
import StudyMateriels from "./Pages/StudyMateriel/StudyMateriels";
import Sub_Category from "./Component/Exam/Studymateriel/Sub_Category";
import MainCategory from "./Component/Exam/Quiz/MainCategory";
import QuizsCategories from "./Pages/Quizs/QuizsCategories";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import NavBar from "./Component/Navbar/Navbar";
const App = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/words" element={<HomePage />} />
        <Route path="/" element={<Navigate to="/words" />} />
        <Route path="/search" element={<Search />} />
        <Route path="/:slug" element={<AllData />} />
        <Route path="/competitive_exams" element={<Home />} />
        <Route path="/exams" element={<Exam />} />
        <Route path="/quiz" element={<Exam />} />
        <Route path="/books" element={<Books />} />
        <Route path="exams/:slug/syllabus" element={<AllSyllbus />} />
        <Route path="exams/:slug/study-materiel" element={<StudyMateriels />} />
        <Route path="/:slug/study-materiel/:slug" element={<Sub_Category />} />
        <Route path="quiz/:slug" element={<StudyMateriels />} />
        <Route path="quiz/:slug/:slug" element={<MainCategory />} />
        <Route path="quiz/:slug/:slug/:slug" element={<QuizsCategories />} />

        <Route
          path="/:slug/study-materiel/:slug/:slug"
          element={<AllSyllbus />}
        />

        {/* <Route path="quiz/:slug" element={<QuizsCategories />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
