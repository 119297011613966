import axios from "axios";
const base_url = "https://admin.kavishala.in";

const Wordsget = (page) => {
  const urlPath = `/words/?page_size=${page}`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const WordsgetSlug = (slug) => {
  const urlPath = `/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const SearchWords = (data, page) => {
  const urlPath = `/find/${data}/?page_size=${page}`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getExam = () => {
  const urlPath = `/preparation/exams/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getExamSlug = (slug) => {
  const urlPath = `/preparation/subject/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const getExamSlugwithSlug = (previous_slug, slug) => {
  const urlPath = `/preparation/chapters/${previous_slug}/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const syllabusData = (slug) => {
  const urlPath = `/preparation/syllabus/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const syllabusStudyMateriel = (slug) => {
  const urlPath = `/preparation/singlechapter/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const QuizCategory = () => {
  const urlPath = `/allquizdata/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const QuizCategorySlug = (previous_slug, slug) => {
  const urlPath = `/preparation/quizlist/${previous_slug}/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};

const Quizs = (slug) => {
  const urlPath = `/preparation/onequiz/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const QuizPopup = (slug) => {
  const urlPath = `/quiz/homepagequiz/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.get(url).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
const QuizsPost = (slug, data) => {
  const urlPath = `/savequizscore/${slug}/`;
  const url = `${base_url}${urlPath}`;

  try {
    const response = axios.post(url, data).then((response) => {
      return response;
    });
    return response;
  } catch (error) {
    return error;
  }
};
export {
  Wordsget,
  WordsgetSlug,
  SearchWords,
  getExam,
  getExamSlug,
  getExamSlugwithSlug,
  syllabusData,
  syllabusStudyMateriel,
  QuizCategory,
  QuizCategorySlug,
  Quizs,
  QuizPopup,
  QuizsPost,
};
