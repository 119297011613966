import React, { useState, useEffect } from "react";
import "../AcademyOther/Alldata.scss";
import { useNavigate, useParams } from "react-router-dom";

const Alldata = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="Alldata_conatiner">
      <div className="Heading">
        <h1 className="words">Word Detail</h1>
      </div>
      <div className="word_row">
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Word:</spna>
            <span>
              {data?.word ? `${data?.word} (${data?.gram_type})` : "-----"}
            </span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Hindi Meaning:</spna>
            <span> {data?.hindi_meaning ? data?.hindi_meaning : "----"} </span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Pronounce:</spna>
            <span> {data?.pronounce ? data?.pronounce : "-----"} </span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Opposite Words:</spna>
            <span> {data?.opp_words ? data?.opp_words : "-----"}</span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Similar Words:</spna>
            <span> {data?.sim_words ? data?.sim_words : "-----"}</span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Rhyming Words:</spna>
            <span> {data?.rhyming_word ? data?.rhyming_word : "-----"}</span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Hindi All Meanings :</spna>
            <span>
              {data?.all_hindi_meanings ? data?.all_hindi_meanings : "-----"}
            </span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Example - 1:</spna>
            <span> {data?.example_1 ? data?.example_1 : "-----"}</span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head">Example - 2:</spna>
            <span> {data?.example_2 ? data?.example_2 : "-----"}</span>
          </h1>
        </div>
        <div className="word_column">
          <h1 className="words">
            <spna className="head"> Word-Defination:</spna>
            <span> {data?.word_def ? data?.word_def : "-----"}</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Alldata;
