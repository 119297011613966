import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../AcademyHome/Home.scss";
import SearchIcon from "@mui/icons-material/Search";
import { SearchWords } from "../../Api/Api";
import SEO from "../../SEO";
const Search = () => {
  const [data, setData] = useState([]);
  const [word, setWord] = useState();
  const [page, setPage] = useState(25);
  const navigate = useNavigate();

  const search = () => {
    SearchWords(word, page)
      .then((res) => {
        setData(res?.data?.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const handleKeyDown = (e) => {
  //   if (e.keyCode === 13 && e.shiftKey === false) {
  //     SearchWords(word)
  //       .then((res) => {

  //         setData(res?.data?.results);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };
  const handleMore = (pageSize) => {
    setPage(page + pageSize);
    SearchWords(word, page)
      .then((res) => {
        setData(res?.data?.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <SEO
        title={`Kavishala Academy | Search`}
        og_url={"/words"}
        og_type="article"
        og_title={`Kavishala Academy | Search`}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <div className="Home_conatiner" style={{ minHeight: "600px" }}>
        <div>
          <h1 className="dataFind">Search Words</h1>
        </div>
        <div className="input_container">
          {/* <input type="text" className="inputBox" /> */}

          <input
            // type="search"
            name="question"
            id="search"
            placeholder="search ......"
            className="inputBox"
            // onKeyDown={handleKeyDown}
            onChange={(e) => setWord(e.target.value)}
            required
          />
          <button className="search_btn" type="submit" onClick={search}>
            <SearchIcon />
          </button>
        </div>
        {data.length === 0 ? (
          ""
        ) : (
          <div className="Heading">
            <h1 className="words">Words List</h1>
          </div>
        )}

        <div className="word_row">
          {data?.map((value) => {
            return (
              <div className="word_column">
                <h1
                  className="words"
                  onClick={() => navigate(`/${value?.slug}`)}
                >
                  {value?.word}
                </h1>
              </div>
            );
          })}
        </div>
        {data.length === 0 ? <h1 className="dataFind"> No Data Found</h1> : ""}
      </div>
      {data.length > 24 ? (
        <div className="btnContainer">
          <button className="Loadmore" onClick={() => handleMore(25)}>
            Load More
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Search;
