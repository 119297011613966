import React, { useState, useEffect } from "react";
import Alldata from "../../Component/AcademyOther/Alldata";
import SEO from "../../SEO";
import { WordsgetSlug } from "../../Api/Api";
import { useParams } from "react-router-dom";
const AllData = () => {
  const { slug } = useParams();
  const [data, setdata] = useState();

  // useEffect(() => {
  //   const loadData = () => {
  //     WordsgetSlug(slug).then((res) => {
  //       setdata(res?.data);
  //     });
  //   };
  //   loadData();
  // }, [slug]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const storedData = localStorage.getItem(slug);
        if (storedData) {
          setdata(JSON.parse(storedData));
        } else {
          const response = await WordsgetSlug(slug);
          const { data } = response;
          setdata(data);
          localStorage.setItem(slug, JSON.stringify(data));
        }
      } catch (error) {
        console.error("Error fetching movie data:", error);
      }
    };
    loadData();
  }, [slug]);
  console.log("kjhbvbn", data);
  return (
    <div>
      <SEO
        title={`Kavishala Academy | ${data?.word}`}
        og_url={`/${data?.slug}`}
        og_type="article"
        og_title={`Kavishala Academy | ${data?.word}`}
        og_description={
          data?.word_def ||
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <Alldata data={data} />
    </div>
  );
};

export default AllData;
