import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../AcademyHome/Home.scss";
import { Wordsget } from "../../Api/Api";
const Home = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(100);
  const navigate = useNavigate();

  const handleLoadmore = (pageSize) => {
    setPage(page + pageSize);

    Wordsget(page).then((res) => {
      setData(res?.data);
    });
  };
  useEffect(() => {
    const loadData = () => {
      Wordsget(page).then((res) => {
        setData(res?.data);
      });
    };
    loadData();
  }, [page]);

  return (
    <div className="Home_conatiner">
      <div className="Heading">
        <h1 className="words">Words List</h1>
      </div>
      <div className="word_row">
        {data?.results?.map((value) => {
          return (
            <div className="word_column">
              <h1 className="words" onClick={() => navigate(`/${value?.slug}`)}>
                {value?.word}
              </h1>
            </div>
          );
        })}
      </div>
      <div className="btnContainer">
        <button className="Loadmore" onClick={() => handleLoadmore(100)}>
          Load More
        </button>
      </div>
    </div>
  );
};

export default Home;
