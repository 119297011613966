import React, { useState, useEffect } from "react";
import { getExamSlugwithSlug } from "../../../Api/Api";
import "../Exams/exam.css";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import SkeletonCard from "../../Skeleton/SkeletonCard";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SEO from "../../../SEO";
const Sub_Category = () => {
  const { slug } = useParams();
  const location = useLocation();
  const paths = location?.pathname;
  const path = location?.pathname?.split("/");
  console.log("path", path);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const loadData = () => {
      getExamSlugwithSlug(path[1], slug)
        .then((res) => {
          if (res.status === 200) {
            setLoading(true);
            setData(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
  }, [slug, path[1]]);
  const Loader = () => {
    return Array(4)
      .fill()
      .map((e, i) => <SkeletonCard key={i} />);
  };
  return (
    <div className="exam-chapter-main-container">
      <SEO
        title={`Kavishala Academy | Competitive Exams | Exams | ${path[1].toUpperCase()} | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}
         `}
        og_type="article"
        og_title={`Kavishala Academy | Competitive Exams | Exams | ${path[1].toUpperCase()} | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}
         `}
        og_url={paths}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      {loading === false ? (
        <div className="SkeletonCard">
          <Loader />
        </div>
      ) : (
        <>
          <center>
            <h1>Study Material Sub Categories</h1>
          </center>

          <div className="exam-chapter-main">
            {data?.map((value) => {
              return (
                <div
                  className="exam-chapter"
                  onClick={() =>
                    navigate(
                      path[1] === "quiz"
                        ? `quiz/${path[2]}/${value?.slug}`
                        : `/${path[1]}/study-materiel/${path[3]}/${value?.slug}`
                    )
                  }
                >
                  <Card>
                    <CardMedia
                      sx={{ height: 150 }}
                      image={value?.img}
                      title={value?.subject_name}
                    />
                  </Card>
                  {/* <CardContent className="cardContent"> */}
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="cardContentName"
                  >
                    {value?.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="cardContentDesp"
                  >
                    {value?.discription}
                  </Typography>
                  {/* </CardContent> */}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Sub_Category;
