import React, { useState } from "react";
// import Authentication from "components/Authentication/Authentication";
// import { Popup } from "devextreme-react";

// import MainCategory from "./MainCategory";
// var logo = "/images/kavishala_logo.png";
// var mainImage = "/images/KavishalaQuiz.png";
import logo from "../../../images/kavishala_logo.png";
import mainImage from "../../../images/KavishalaQuiz.png";
import "./Quizs.css";
import MainCategory from "./MainCategory";
const QuizHome = () => {
  const [show, setShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  var data = 0;
  const handleShow = () => {
    console.log("lkjhghj");
    setShow(true);
  };
  // const handleClose = () => setIsShow(false);
  // const renderPopupComponent = () => {
  //   return <Authentication />;
  // };
  return (
    <>
      <div className="mainContainer1">
        {!show && (
          <div className="container1">
            <div className="logoContainer">
              <img className="" src={logo} alt="kavishala-image" width={100} />
              <h1 className="QuizHeading">Literary quiz</h1>
            </div>

            <div className="secondContainer">
              <h1 className="pargraph">
                The Literary Conundrum: Solve the Quiz and Conquer the Classics
              </h1>
              <h1 className="pargraph">
                30 coins will be deducted from your wallet to play any quizs
              </h1>
              <button className="get_start" onClick={handleShow}>
                Start
              </button>
            </div>

            <div className="mainImage">
              <img src={mainImage} className="Quizimages" />
            </div>
          </div>
        )}
        {/* <Popup
          visible={isShow}
          hideOnOutsideClick={true}
          onHiding={handleClose}
          contentComponent={renderPopupComponent}
          showCloseButton={true}
          showTitle={false}
          width={"25%"}
          height={"60%"}
          className={"authentication-popup"}
          focusStateEnabled={false}
        /> */}
      </div>
      {show && <MainCategory />}
    </>
  );
};
export default QuizHome;
