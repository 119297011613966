import React, { useState, useEffect } from "react";
import Syllabus from "../../Component/Exam/Syllabus/Syllabus";
import { syllabusData, syllabusStudyMateriel } from "../../Api/Api";
import { useParams } from "react-router-dom";
import SEO from "../../SEO";
const AllSyllbus = () => {
  const { slug } = useParams();
  console.log("kjhbvbn jhgfghj", slug);
  const [data, setData] = useState();
  const path = window.location.pathname;
  useEffect(() => {
    const loadData = () => {
      syllabusData(slug)
        .then((res) => {
          if (res?.status === 200) {
            console.log("responsejhghhgh", res?.data);
            setData(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
  }, [slug]);
  useEffect(() => {
    const loadData = () => {
      syllabusStudyMateriel(slug)
        .then((res) => {
          if (res?.status === 200) {
            console.log("response", res?.data);
            setData(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    loadData();
  }, [slug]);

  return (
    <div>
      <SEO
        title={`Kavishala Academy | Competitive Exams | Exams | ${data?.title} `}
        og_url={path}
        og_type="article"
        og_title={`Kavishala Academy | Competitive Exams | Exams | ${data?.title} `}
        og_description={data?.raw_content}
        og_image={data?.img}
      />
      <Syllabus data={data} />
    </div>
  );
};

export default AllSyllbus;
