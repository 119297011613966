// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/TTF/Octin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

@font-face {
    font-family: "Octin";
    src: local("Octin"),
      url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
    font-weight: bold;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,SAAS;EACT;;cAEY;;EAEZ,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,YAAY;AACd;;AAEA;IACI,oBAAoB;IACpB;gEACiD;IACjD,iBAAiB;EACnB","sourcesContent":["\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  height: 100%;\n}\n\n@font-face {\n    font-family: \"Octin\";\n    src: local(\"Octin\"),\n      url(\"./Fonts/TTF/Octin.ttf\") format(\"truetype\");\n    font-weight: bold;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
