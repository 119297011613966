import React from "react";
import Home_Category from "../../Component/Exam/Home_Category";
import SEO from "../../SEO";
const Home = () => {
  return (
    <div>
      <SEO
        title={`Kavishala Academy | Competitive Exams`}
        og_url={`/competitive_exams`}
        og_type="article"
        og_title={`Kavishala Academy | Competitive Exams`}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <Home_Category />
    </div>
  );
};

export default Home;
