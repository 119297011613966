import React from "react";
const QuestionBtn = ({ selected, setSelected }) => {
  const navs = [
    { key: "Score", text: "Score" },
    { key: "Answers", text: "Answers" },
    { key: "LeaderBoad", text: "LeaderBoad" },
  ];

  return (
    <div className="quetion-buttonmain">
      {navs.map(({ key, text }) => (
        <div
          key={key}
          id={selected === key ? "selected-btn" : null}
          className="Questionsbtn"
          onClick={() => setSelected(key)}
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default QuestionBtn;
