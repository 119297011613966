import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
const ScoreDashBoard = ({ score, coins }) => {
  const navigate = useNavigate();
  let percentage = parseFloat(score).toFixed(2);
  const [earn, setEarn] = useState(false);

  useEffect(() => {
    const loadData = () => {
      {
        setTimeout(() => {
          setEarn(true);
        }, 3000);
      }
    };
    loadData();
  }, []);

  return (
    <div>
      <div className="ScoreContainer">
        <h6 className="QuizHeading" style={{ textAlign: "center" }}>
          Thank You
        </h6>
        <Confetti style={{ width: "100%" }} />
        <div className="Congrates">Congrats</div>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}% `}
          className="circullerbar"
        />
        <button
          className="get_start"
          style={{ marginTop: "0" }}
          onClick={() => navigate("/quiz")}
        >
          Back To Quizs
        </button>
      </div>
    </div>
  );
};

export default ScoreDashBoard;
