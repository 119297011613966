import React, { useState, useEffect } from "react";
import Category from "../../Component/Exam/Studymateriel/Category";
import Exam from "../../Component/Exam/Exams/Exam";
import SEO from "../../SEO";
import { useLocation } from "react-router-dom";

const StudyMateriels = () => {
  const location = useLocation();
  const paths = location?.pathname;
  const path = location?.pathname?.split("/");
  console.log("kjhgvfghj", path[2].toUpperCase());
  return (
    <div>
      <SEO
        title={`Kavishala Academy | ${
          path[1] === "quiz"
            ? `${path[2].toUpperCase()}`
            : `Competitive Exams | Exams | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}`
        }`}
        og_type="article"
        og_url={paths}
        og_title={`Kavishala Academy | ${
          path[1] === "quiz"
            ? `${path[2].toUpperCase()}`
            : `Competitive Exams | Exams | ${path[2].toUpperCase()} | ${path[3].toUpperCase()}`
        }`}
        og_description={
          "Unlock the door to boundless wisdom and ignite your curiosity on our knowledge page – where every click leads to enlightenment."
        }
        og_image="https://academy.kavishala.com/images/post_og.png"
      />
      <Category />
    </div>
  );
};

export default StudyMateriels;
